$white: #ffffff;
$black: rgba(0, 0, 0, 0.87);

$body-color: rgba($black, 0.7);
$heading-color: $black;

$primary: #607d8b;
$secondary: #303030;

$brand: $primary;
$brand-active: darken($brand, 10);

$topbar-mobile-width: 220px;
